<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-tabs
        v-model="tab"
        align-tabs="start"
        color="#FFB300"
        slider-color="white"
      >
        <v-tab elevation="6" value="client">Client</v-tab>
        <v-tab elevation="6" value="utilisateur">Utilisateur</v-tab>
        <v-tab elevation="6" value="produit">Produit</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="client">
          <client-admin></client-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="utilisateur">
          <user-admin></user-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="produit">
          <product-admin></product-admin>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>
  </v-container>
</template>

<script>
import ClientAdmin from "@/components/ClientAdmin.vue";
import UserAdmin from "@/components/UserAdmin.vue";
import ProductAdmin from "@/components/ProductAdmin.vue";

export default {
  name: "AdminView",
  components: {
    ClientAdmin,
    UserAdmin,
    ProductAdmin
  },
  data() {
    return {
      tab: null
    };
  }
};
</script>

<style scoped>
.v-btn.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab:not(
    .v-tab-item--selected
  ):not(.v-tab--selected) {
  background-color: #ffb300;
  color: white;
}
</style>

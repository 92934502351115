import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import Contrat from "@/views/ContactView.vue";
import StrykerCommands from "@/views/StrykerCommands.vue";
import StrykerOneCommands from "@/views/StrykerOneCommands.vue";
import ContratView from "@/views/ContratView.vue";
import AdminView from "@/views/AdminView.vue";

import store from "../store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/contact",
    name: "contact",
    component: Contrat
  },
  {
    path: "/stryker-commands",
    name: "stryker-commands",
    component: StrykerCommands
  },
  {
    path: "/stryker-one-commands",
    name: "stryker-one-commands",
    component: StrykerOneCommands
  },
  {
    path: "/contrat",
    name: "contrat",
    component: ContratView
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView
  },
  {
    /* path: '/about',
    name: 'about', */
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

router.beforeEach((to, from, next) => {
  if (isEmpty(store.state.auth.user)) {
    var token = localStorage.getItem("authToken");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      var user = localStorage.getItem("user");
      store.commit("auth/setUser", JSON.parse(user));
      store.commit("auth/setToken", token);
    }
  }
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = !isEmpty(store.state.auth.user);

  // Redirection si l'utilisateur est déjà connecté et essaie d'accéder à /login
  if (loggedIn && to.path === "/login") {
    return next("/"); // Redirection vers la page d'accueil
  }

  if (authRequired && !loggedIn) {
    return next("/login");
  }
  return next();
});

export default router;

import { createStore } from "vuex";
import auth from "./modules/auth";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth
  }
});

<template>
  <v-app>
    <top-bar @update="menu = !menu"></top-bar>

    <v-navigation-drawer
      v-model="menu"
      floating
      :location="$vuetify.display.mobile ? 'bottom' : undefined"
      temporary
      class="navigation-width"
    >
      <v-list density="compact" nav>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-5 title-menu"
          value="mes points"
          @click="pushView('home')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Utiliser mes points</span>
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiStarOutline" size="40"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="mes commandes one stryker"
          @click="pushView('stryker-one-commands')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Mes commandes One Stryker</span>
          </template>
          <template v-slot:prepend>
            <v-icon
              color="#feb100"
              :icon="mdiPackageVariant"
              size="40"
            ></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="mes commandes stryker"
          @click="pushView('stryker-commands')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Mes commandes Stryker</span>
          </template>
          <template v-slot:prepend>
            <v-icon
              color="#feb100"
              :icon="mdiPackageVariant"
              size="40"
            ></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="Mon contrat"
          @click="pushView('contrat')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Mon contrat</span>
          </template>
          <template v-slot:prepend>
            <v-icon
              color="#feb100"
              :icon="mdiAccountBoxOutline"
              size="40"
            ></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="Service One Stryker"
          @click="pushView('home')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Service One Stryker</span>
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiCogOutline" size="40"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="contact"
          @click="pushView('contact')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Contact</span>
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiForumOutline" size="40"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          active-class="active-menu elevation-6"
          class="mt-3 mb-3 title-menu"
          value="admin"
          @click="pushView('admin')"
        >
          <template v-slot:title>
            <span class="text-subtitle-1"> Administration</span>
          </template>
          <template v-slot:prepend>
            <v-icon color="#feb100" :icon="mdiDatabaseCog" size="40"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>

<script>
import FooterComponent from "./components/FooterComponent.vue";
import TopBar from "./components/TopBar.vue";
import {
  mdiStarOutline,
  mdiPackageVariant,
  mdiAccountBoxOutline,
  mdiCogOutline,
  mdiForumOutline,
  mdiDatabaseCog
} from "@mdi/js";
export default {
  name: "App",
  components: {
    FooterComponent,
    TopBar
  },
  data() {
    return {
      menu: null,
      mdiStarOutline,
      mdiPackageVariant,
      mdiAccountBoxOutline,
      mdiCogOutline,
      mdiForumOutline,
      mdiDatabaseCog,
      items: [
        { text: "Mes points", icon: mdiStarOutline, view: "home" },
        { text: "Mes commandes", icon: mdiPackageVariant, view: "home" },
        { text: "Mon contrat", icon: mdiAccountBoxOutline, view: "home" },
        { text: "Service One Stryker", icon: mdiCogOutline, view: "home" },
        { text: "Contact", icon: mdiForumOutline, view: "contact" }
      ]
    };
  },
  methods: {
    pushView(view) {
      if (this.$route.name != view) {
        this.$router.push({ name: view });
      }
    }
  }
};
</script>

<style>
.v-navigation-drawer
  .v-navigation-drawer--left
  .v-navigation-drawer--floating
  .v-navigation-drawer--temporary
  .v-navigation-drawer--active
  .v-theme--light
  .navigation-width {
  width: 350px !important;
}
.active-menu {
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.title-menu {
  color: black;
  font-size: 1rem !important;
}

.align-icon-right {
  text-align: right;
}
</style>

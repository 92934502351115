<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :sort-by="[{ key: 'calories', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ props }">
              <v-btn class="mb-2" color="#ffb300" dark v-bind="props">
                Nouveau Client
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.customerNumber"
                        label="Client number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.customerName"
                        label="Nom client"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.city"
                        label="Ville"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.address"
                        label="Adresse"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.statut"
                        label="Statut"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.points"
                        label="Points"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="close">
                  Retour
                </v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="save">
                  Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Voulez vous supprimer ce client ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="closeDelete"
                >
                  Retour
                </v-btn>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.statut="{ item }">
        <v-chip color="#ffb300" dark>
          {{ item.statut }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete } from "@mdi/js";

export default {
  name: "ClientAdmin",
  data() {
    return {
      mdiPencil,
      mdiDelete,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          title: "Client number",
          align: "start",
          sortable: false,
          key: "customerNumber"
        },
        { title: "Nom client", key: "customerName" },
        { title: "Ville", key: "city" },
        { title: "Adresse", key: "address" },
        { title: "Statut", key: "statut" },
        { title: "Points", key: "points" },
        { title: "Actions", key: "actions", sortable: false }
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        customerNumber: "",
        customerName: "",
        city: "",
        address: "",
        statut: 1,
        points: 0
      },
      defaultItem: {
        customerNumber: "",
        customerName: "",
        city: "",
        address: "",
        statut: 1,
        points: 0
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau Client" : "Modification Client";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      const randomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min;
      const randomCustomerNumber = () => `FTT${randomInt(1000, 9999)}`;
      const randomCustomerName = () => {
        const names = ["Alice", "Bob", "Charlie", "David", "Eve"];
        return names[randomInt(0, names.length - 1)];
      };
      const randomCity = () => {
        const cities = [
          "New York",
          "Los Angeles",
          "Chicago",
          "Houston",
          "Phoenix"
        ];
        return cities[randomInt(0, cities.length - 1)];
      };
      const randomAddress = () => randomInt(1, 1000);
      const randomStatut = () => randomInt(1, 4);
      const randomPoints = () => randomInt(1, 500000);

      this.desserts = Array.from({ length: 10 }, () => ({
        customerNumber: randomCustomerNumber(),
        customerName: randomCustomerName(),
        city: randomCity(),
        address: randomAddress(),
        statut: randomStatut(),
        points: randomPoints()
      }));
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>

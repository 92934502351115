<template>
  <v-container class="container-view" fluid>
    <v-row class="header-home-content">
      <v-col cols="5" class="pr-0">
        <v-img src="../assets/home_client.png" height="100%" cover> </v-img>
      </v-col>

      <v-col cols="7" class="pl-0">
        <div class="container-frise">
          <v-img
            src="../assets/frise.png"
            class="frise-img"
            height="100%"
            cover
          >
            <h1 class="welcome-client">Bienvenue sur votre espace</h1>
            <div class="recap-div">
              CHU Toulouse ( FRC000TEST ) Status 3 -
              <span class="color-point"> 14 030 points</span>
            </div>
          </v-img>
        </div>
      </v-col>
    </v-row>

    <v-row class="pt-5 pb-8 row-second-container">
      <v-col cols="7">
        <!-- <v-card height="52vh" elevation="6">
          <v-card-title class="title-background">
            Mes dernières commandes
          </v-card-title>
          <v-card-text>
            <div>
              <v-data-table :items="commandes"> </v-data-table>
            </div>
          </v-card-text>
        </v-card> -->
        <v-card
          class="mx-auto text-center"
          color="white"
          max-width="1200"
          dark
          elevation="6"
        >
          <v-card-title class="title-background text-left">
            Quelques chiffres
          </v-card-title>
          <v-carousel
            height="400"
            max-width="1200"
            show-arrows="hover"
            hide-delimiter-background
          >
            <v-carousel-item>
              <first-carousel-item></first-carousel-item>
            </v-carousel-item>
            <v-carousel-item>
              <second-carousel-item></second-carousel-item>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card elevation="6">
          <v-card-title class="title-background"> Mon Espace </v-card-title>
          <v-card-text>
            <v-list density="compact">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :value="item"
                @click="pushView(item.view)"
                color="primary"
                class="pb-3 pt-3"
              >
                <template v-slot:prepend>
                  <v-icon color="#feb100" :icon="item.icon" size="50"></v-icon>
                </template>
                <!-- eslint-disable -->
                <v-list-item-title
                  class="text-h6"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import FirstCarouselItem from "@/components/FirstCarouselItem.vue";
import SecondCarouselItem from "@/components/SecondCarouselItem.vue";

import {
  mdiStarOutline,
  mdiPackageVariant,
  mdiAccountBoxOutline,
  mdiCogOutline,
  mdiForumOutline,
  mdiDatabaseCog
} from "@mdi/js";

export default defineComponent({
  name: "HomeView",
  components: {
    FirstCarouselItem,
    SecondCarouselItem
  },
  data() {
    return {
      mdiStarOutline,
      mdiPackageVariant,
      mdiAccountBoxOutline,
      mdiCogOutline,
      mdiForumOutline,
      value: [423, 446, 675, 510, 590, 610, 760, 400, 850, 900, 1200, 548, 154],
      items: [
        { text: "Utiliser mes points", icon: mdiStarOutline, view: "home" },
        {
          text: "Mes commandes One Stryker",
          icon: mdiPackageVariant,
          view: "stryker-one-commands"
        },
        {
          text: "Mes commandes Stryker",
          icon: mdiPackageVariant,
          view: "stryker-commands"
        },
        { text: "Mon contrat", icon: mdiAccountBoxOutline, view: "contrat" },
        { text: "Service One Stryker", icon: mdiCogOutline, view: "home" },
        { text: "Contact", icon: mdiForumOutline, view: "contact" },
        { text: "Administration", icon: mdiDatabaseCog, view: "admin" }
      ]
    };
  },
  methods: {
    pushView(view) {
      if (this.$route.name != view) {
        this.$router.push({ name: view });
      }
    }
  }
});
</script>

<style>
.recap-div {
  position: absolute;
  bottom: 20%; /* Place l'élément au bas de l'image */
  left: 0; /* Optionnel : aligne l'élément à gauche */
  right: 0; /* Optionnel : étend l'élément sur toute la largeur */
  text-align: left; /* Centrer le texte horizontalement */
}
.text-title-stats {
  color: #535756;
}

.color-point {
  color: #ffb500;
}

.header-home-content {
  margin-bottom: 25px;
  height: 200px;
}

@media (min-width: 1200px) {
  .header-home-content {
    margin-bottom: 50px;
    height: 225px;
  }
}

@media (min-width: 1300px) {
  .row-second-container {
    margin-top: 150px !important;
  }
}

@media (min-width: 1600px) {
  .row-second-container {
    margin-top: 200px !important;
  }
}

/* @media (min-width: 1200px) {
  .header-home-content {
    padding-bottom: 100px;
  }
} */

.container-view {
  margin-top: 30px;
}

.title-background {
  background-color: #535756;
  color: white;
}

.container-frise {
  height: 100%; /* Hauteur de ta div principale, à ajuster */
  background-color: #ffffff; /* Couleur de fond de ta div principale, à ajuster */
}

.frise-img {
  margin-top: 3px;
  position: relative;
}

.welcome-client {
  color: white;
  position: absolute;
  top: 40%;
  left: 0;
}
</style>

import auth from "../../api/auth";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("authToken") || null,
    loading: false,
    user: null
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getUser: (state) => state.user
  },
  actions: {
    validateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        auth
          .validateUser(data)
          .then((response) => {
            commit("setLoading", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    /* login({ commit }, { token, user }) {
      // Stocker le token dans le localStorage
      localStorage.setItem("authToken", token);
      // Commit les mutations pour mettre à jour l'état
      commit("setToken", token);
      commit("setUser", user);
    },
    logout({ commit }) {
      // Retirer le token du localStorage
      localStorage.removeItem("authToken");
      // Clear l'état dans Vuex
      commit("clearAuth");
    },
    async fetchUser({ commit }) {
      const response = await fetch("/api/user", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`
        }
      });
      const user = response.json();
      commit("setUser", user);
    } */
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("authToken", token);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clearAuthData(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  }
};

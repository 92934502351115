<template>
  <v-container fluid>
    <v-row>
      <!-- <v-col v-for="i in 6" :key="i" cols="12" md="4">
        <v-img
          :lazy-src="`https://picsum.photos/10/6?image=${i * 3 * 5 + 10}`"
          :src="`https://picsum.photos/500/300?image=${i * 3 * 5 + 10}`"
          height="205"
          cover
        ></v-img>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProductAdmin"
  // Options du composant ici
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>

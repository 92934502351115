<template>
  <v-container>
    <v-row class="pt-5">
      <v-col cols="5" class="total-points-sheet">
        <v-sheet
          elevation="8"
          class="d-flex align-center justify-center flex-wrap text-center"
          height="200"
          width="250"
        >
          <div>
            <h2 class="text-h2 font-weight-black text-color">14.03K</h2>

            <div class="text-body-2 mt-2">Totals des points en 2024</div>
          </div>
        </v-sheet>
      </v-col>

      <v-col cols="7">
        <div>
          <v-sheet
            class="first-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col cols="2" class="pr-0">
                <h2 class="text-h6 font-weight-regular">
                  {{ new Date().getFullYear() }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                class="border-opacity-100 divider-height"
                vertical
              ></v-divider>
              <v-col>
                <h2 class="text-h5 text-center font-weight-bold">
                  Statut 3 - 1,66M€ vente {{ new Date().getFullYear() }}
                </h2>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <div>
          <v-sheet
            class="second-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col cols="2" class="pr-0">
                <h2 class="text-h6 font-weight-regular">
                  {{ new Date().getFullYear() + 1 }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                class="border-opacity-100 divider-height"
                vertical
              ></v-divider>
              <v-col>
                <h2 class="text-h5 text-center font-weight-bold">
                  Statut 3 - 239,24M€ vente {{ new Date().getFullYear() + 1 }}
                </h2>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">141</h2>

            <div class="text-caption mt-2">
              Points {{ new Date().getFullYear() }} Capital
            </div>
          </div>
        </div>
      </v-col>

      <v-divider :thickness="1" class="border-opacity-50" vertical></v-divider>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">4,26K</h2>

            <div class="text-caption mt-2">
              Points {{ new Date().getFullYear() }} Consommable
            </div>
          </div>
        </div>
      </v-col>

      <v-divider :thickness="1" class="border-opacity-50" vertical></v-divider>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">9K</h2>

            <div class="text-caption mt-2">
              Points {{ new Date().getFullYear() }} Implant
            </div>
          </div>
        </div>
      </v-col>

      <v-divider :thickness="1" class="border-opacity-50" vertical></v-divider>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">268</h2>

            <div class="text-caption mt-2">
              Points {{ new Date().getFullYear() }} Services
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FirstCarouselItem",
  data() {
    return {
      color: "indigo",
      slide: "First"
    };
  }
};
</script>

<style scoped>
.container-text-statut {
  text-align: center;
}

.first-sheet-carousel-command {
  margin-bottom: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.second-sheet-carousel-command {
  margin-top: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.total-points-sheet {
  padding-left: 50px;
}

.text-color {
  color: #feb100;
}
</style>

export default {
  auth: {
    clientId: process.env.VUE_APP_CLIENT,
    authority: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    redirectUri: process.env.VUE_APP_CALLBACK_URL,
    //redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/' // Must be registered as a SPA redirectURI on your app registrationregistration
  },
  cache: {
    cacheLocation: "sessionStorage",
    temporaryCacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

<template>
  <v-container class="container-view">
    <v-card elevation="6">
      <v-card-title class="title-background"> Mes Contrats </v-card-title>
      <v-card-text>
        <div>
          <v-data-table :items="contracts">
            <!-- eslint-disable-next-line-->
            <template v-slot:item.file="{ item }">
              <v-icon
                :icon="item.file.type == 'PDF' ? mdiFilePdfBox : mdiFileWordBox"
                size="x-large"
              ></v-icon>
              {{ item.file.nom }}
            </template>
            <!-- eslint-disable-next-line-->
            <template v-slot:item.actions="{ item }">
              <v-icon
                :icon="mdiDownload"
                @click="downloadFile(item.file.path)"
                size="x-large"
              >
              </v-icon>
            </template>
            <!-- eslint-disable-next-line-->
            <template v-slot:item.size="{ item }">
              <span>
                {{ item.size }}
              </span>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiFilePdfBox, mdiFileWordBox, mdiDownload } from "@mdi/js";

export default {
  name: "ContratView",
  data() {
    return {
      mdiFilePdfBox,
      mdiFileWordBox,
      mdiDownload,
      contracts: [
        {
          file: {
            type: "PDF",
            nom: "Mon contrat",
            path: ""
          },
          size: "4MB",
          Uploader: "Justine",
          actions: ""
        },
        {
          file: {
            type: "PDF",
            nom: "Condition general",
            path: ""
          },
          size: "5MB",
          Uploader: "Pierre-Olivier",
          actions: ""
        },
        {
          file: {
            type: "PDF",
            nom: "Déclaration de confidentialité",
            path: ""
          },
          size: "6MB",
          Uploader: "Florian",
          actions: ""
        }
      ]
    };
  },
  methods: {
    downloadFile(file) {
      return file;
    }
  }
};
</script>

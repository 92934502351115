<template>
  <v-container class="login-container" fluid>
    <div class="background-overlay"></div>

    <v-card width="400" class="login-card" color="#FFFFFF">
      <v-card-title> Se connecter </v-card-title>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :counter="40"
            required
            variant="outlined"
            label="Identifiant"
          >
            <template v-slot:prepend-inner>
              <v-icon
                :icon="mdiAccountOutline"
                size="large"
                class="mr-3"
              ></v-icon>
              <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            class="me-4 mb-5 color-button"
            @click="submit"
            :loading="loading"
            variant="elevated"
            :prepend-icon="mdiLogin"
          >
            Se connecter
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mdiAccountOutline, mdiLogin } from "@mdi/js";
import axios from "axios";

export default {
  name: "LoginComponent",
  data() {
    return {
      mdiAccountOutline,
      mdiLogin,
      valid: false,
      loading: false,
      email: "",
      emailRules: [
        (value) => {
          if (value) return true;
          return "Email required";
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;
          return "Invalid Email";
        }
      ]
    };
  },
  inject: ["$msalInstance"],
  mounted() {
    this.$msalInstance.initialize();
  },
  methods: {
    async submit() {
      this.loading = true;
      const { valid } = await this.$refs.form.validate();

      try {
        if (valid) {
          const data = {
            Email: this.email
          };
          await this.$store.dispatch("auth/validateUser", data);
          // Authentifie l'utilisateur avec Microsoft
          await this.$msalInstance.loginPopup({
            scopes: ["user.read"]
          });
          const myAccount = this.$msalInstance.getAllAccounts();
          this.$msalInstance.account = myAccount[0];

          const response = await this.$msalInstance.acquireTokenSilent({
            account: this.$msalInstance.account,
            scopes: [`api://${process.env.VUE_APP_CLIENT}/auth`]
          });

          const token = response.accessToken;
          axios.defaults.headers.Authorization = `Bearer ${token}`;

          // Stocke le token et l'utilisateur dans le store
          this.$store.commit("auth/setToken", token);
          this.$store.commit("auth/setUser", response.account);

          this.$router.push({ name: "home" });
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        // TODO GENERATE SNACKBAR ERROR
        console.error("Authentication or validation failed:", error);
        alert("Authentication or validation failed: " + error.message);
      }
    }
  }
};
</script>

<style>
.login-container {
  height: 100%;
  display: flex; /* Active Flexbox */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  position: relative; /* Nécessaire pour le positionnement absolu de l'overlay */
}

.background-overlay {
  background-image: url("../assets/login_background.jpg");
  background-size: cover;
  background-position: center; /* Centre l'image */
  filter: blur(10px) grayscale(100%);
  -webkit-filter: blur(10px) grayscale(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Couleur blanche */
  opacity: 0.5; /* Ajuste l'opacité ici */
  z-index: 1; /* Assure que l'overlay est au-dessus de l'image mais en dessous du contenu */
  pointer-events: none; /* Permet aux clics de passer à travers ce calque */
}

.color-button {
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}

.login-card {
  z-index: 2;
  margin-top: -200px; /* Default for large screens */
}

@media (min-width: 1200px) {
  .login-card {
    margin-top: -200px; /* Large screens */
  }
}

@media (max-height: 810px) {
  .login-card {
    margin-top: 0px; /* Medium screens */
  }

  .v-footer {
    max-height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .login-card {
    margin-top: 0px; /* Medium screens */
  }

  .v-footer {
    max-height: 150px;
  }
}

@media (max-width: 767px) {
  .login-card {
    margin-top: -50px; /* Small screens */
  }
}

@media (max-width: 480px) {
  .login-card {
    margin-top: 0; /* Extra small screens */
  }
}
</style>

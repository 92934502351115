<template>
  <v-container fluid class="px-0 py-0">
    <v-footer color="#545857" height="10px" class="customer-footer">
      <v-row no-gutters>
        <v-col>
          <v-row class="pt-0 pb-0">
            <v-col
              cols="2"
              xl="2"
              lg="2"
              md="2"
              sm="3"
              xs="8"
              class="align-stryker text-center pt-0 pb-0"
            >
              © Stryker 1998- {{ new Date().getFullYear() }}
            </v-col>
            <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            <v-col
              cols="2"
              xl="2"
              lg="2"
              md="2"
              sm="3"
              xs="8"
              class="text-center pt-0 pb-0"
            >
              <v-btn
                variant="text"
                @click="
                  openUrl('https://www.stryker.com/fr/fr/legal/privacy.html')
                "
              >
                CONFIDENTIALITÉ
              </v-btn>
            </v-col>
            <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            <v-col
              cols="3"
              xl="2"
              lg="3"
              md="3"
              sm="4"
              xs="8"
              class="text-center pt-0 pb-0"
            >
              <v-btn
                variant="text"
                @click="
                  openUrl(
                    'https://www.stryker.com/fr/fr/legal/website-accessibility.html'
                  )
                "
              >
                DÉCLARATION D'ACCESSIBILITÉ
              </v-btn>
            </v-col>
            <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            <v-col
              cols="6"
              xl="4"
              lg="5"
              md="6"
              sm="8"
              xs="8"
              class="text-center pt-0 pb-0"
            >
              <v-btn
                variant="text"
                @click="
                  openUrl(
                    'https://www.stryker.com/fr/fr/legal/surgeon-disclaimer.html'
                  )
                "
              >
                AVERTISSEMENTS À L'INTENTION DES PROFESSIONNELS DE SANTÉ
              </v-btn>
            </v-col>
            <v-divider
              class="border-opacity-50 my-3 pt-0 pb-0"
              vertical
            ></v-divider>
            <v-col
              cols="3"
              xl="2"
              lg="2"
              md="3"
              sm="4"
              xs="8"
              class="text-center pt-0 pb-0"
            >
              <v-btn
                variant="text"
                @click="
                  openUrl(
                    'https://www.stryker.com/fr/fr/legal/terms-of-use.html'
                  )
                "
              >
                CONDITIONS D'UTILISATION
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="font-weight-bold pl-4 pt-0 pb-2">
            enregistrée sous le numéro 333 710 275 RCS LYON
          </v-row>
          <v-row class="pt-0 pb-0">
            <v-btn
              variant="text"
              @click="
                openUrl(
                  'https://www.stryker.com/productexperience/Legal/Agreement'
                )
              "
            >
              EXPÉRIENCE PRODUIT
            </v-btn>
            <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            <v-btn
              variant="text"
              @click="
                openUrl(
                  'https://app.convercent.com/fr-fr/Anonymous/IssueIntake/LandingPage/b6bb4e84-9fcb-ea11-a974-000d3ab9f296'
                )
              "
            >
              LIGNE D'ASSISTANCE ÉTHIQUE
            </v-btn>
            <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            <v-btn
              variant="text"
              @click="openUrl('https://www.stryker.com/fr/fr/site-map.html')"
            >
              PLAN DU SITE
            </v-btn>
          </v-row>
        </v-col>
        <!-- <v-col cols="2"></v-col> -->
        <v-col cols="2">
          <v-row>
            <v-col class="align-icon-right">
              <img
                @click="openUrl('https://www.facebook.com/strykercareers/')"
                :src="linkedin"
                :sizes="$vuetify.display.mobile ? 20 : 50"
              />
            </v-col>
            <v-col>
              <img
                @click="openUrl('https://www.linkedin.com/company/stryker')"
                :src="facebook"
                :sizes="$vuetify.display.mobile ? 20 : 50"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import linkedin from "../assets/icon-social-t.png";
import facebook from "../assets/icon-social-f.png";

export default {
  name: "FooterComponent",
  data() {
    return {
      linkedin: linkedin,
      facebook: facebook
    };
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    }
  },
  created() {}
};
</script>

<style>
.align-stryker {
  align-content: center;
}

.customer-footer {
  height: 200px !important;
  max-height: 200px;
}
</style>

<template>
  <v-container class="container-view">
    <v-card height="52vh" elevation="6">
      <v-card-title class="title-background">
        Mes dernières commandes Stryker
      </v-card-title>
      <v-card-text>
        <div>
          <v-data-table :items="commandes"> </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "StrykerCommands",
  data() {
    return {
      commandes: [
        {
          date: "19/08/2024",
          nom: "Alpha",
          total: "7000",
          pts: "7000"
        },
        {
          date: "20/08/2024",
          nom: "Beta",
          total: "8000",
          pts: "8000"
        },
        {
          date: "21/08/2024",
          nom: "Charlie",
          total: "10000",
          pts: "10000"
        },
        {
          date: "22/08/2024",
          nom: "Delta",
          total: "2000",
          pts: "2000"
        },
        {
          date: "23/08/2024",
          nom: "Echo",
          total: "14000",
          pts: "14000"
        },
        {
          date: "24/08/2024",
          nom: "Foxtrot",
          total: "1000",
          pts: "1000"
        }
      ]
    };
  },
  methods: {}
};
</script>
